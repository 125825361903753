<!--  -->
<template>
  <html style="padding:15px">

     <div style="position:absolute;bottom:0.5%;height:50px;width:100px;border-radius:0px 10px 10px 0;background-color:rgba(233,233,233,0.98);z-index:999"></div>
  <div id="container">

  </div>

  <div class="info">
    定位:<input type="text" id="suggestId" size="20" value=""  class="inpt1" />
    <button @click="switchMapType()">切换模式</button>
    <button @click="conmitDevice()">提交此坐标</button>
    <button @click="noConmitDevice()">取消</button>
  </div>

  <div id="searchResultPanel" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>


  </html>
</template>

<script>
import dataStorage from '@/../utils/dataStorage.js'
  import BaseData from "../../assets/config/BaseData";
    import jQuery from 'jquery'
  import $ from 'jquery'
  export default {
    props:{
      deviceId:""
    },
    data () {
      return {

        baseURL:BaseData.baseUrl,
        //开关看是否是删除的点击事件
        deleteBuidinFlag : 0,
        ciID_ciId:"0",
        map:{},
        ac:{},
        myValue:"",
        //    切换模式
        mapTypeFlag : 0,
        submitWindows:false,
        submitWindowsDeviceInfo:false,
        dataForSon:{
          baseUrl:BaseData.baseUrl,
          message:this.message,
          ciId:"0",
        },
        dataForSonDeviceInfo:{
          baseUrl:BaseData.baseUrl,
          message:this.message,
          ciId:"0",
        },

      };
    },
    components: {
    },

    computed: {},

    mounted() {

      this.init()





    },

    methods: {
      // 返回
       goSelect() {
      this.$router.push("/Select");
    },
      // 百度地图API功能
      G(id) {
        return document.getElementById(id);
      },
      setPlace(){
        var that = this
        this.map.clearOverlays();    //清除地图上所有覆盖物
        function myFun(){
          var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
          that.map.centerAndZoom(pp, 18);
          //map.addOverlay(new BMapGL.Marker(pp));    //添加标注
        }
        var local = new BMapGL.LocalSearch(that.map, { //智能搜索
          onSearchComplete: myFun
        });
        local.search(this.myValue);
      },


      switchMapType() {

        if (this.mapTypeFlag == 0){
          this.map.setMapType(BMAP_EARTH_MAP); //卫星地图
          this.mapTypeFlag =1
        }else{
          this.map.setMapType(BMAP_NORMAL_MAP); //普通地图
          this.mapTypeFlag =0
        }

      },

      //删除点位数组里的指定点 并返回删除后的新数组
      deleteOverlayBymake(markerLslslsls) {
        this.deleteBuidinFlag = 1
        this.map.removeOverlay(markerLslslsls);    //删除指定的覆盖物
      },
      conmit() {
        var that = this
        let MarkToString = "{";
        for(let i in that.map.getOverlays()){
          if(i > 0)
            MarkToString = MarkToString+","
          MarkToString =MarkToString + "\""+(parseInt(i) +1)+"\":{\"lng\":\""+that.map.getOverlays()[i].getPosition().lng+"\",\"lat\":\""+that.map.getOverlays()[i].getPosition().lat+"\"}"
        }
        MarkToString = MarkToString+"}"


        if(MarkToString.length > 6){
          this.fun_add(MarkToString)
        }else(
                alert("请选择点位")
        )



      },
      noConmitDevice(){
        var that = this
        that.map.clearOverlays();
        this.$parent.$parent.noselectLola()
      },

      conmitDevice() {
        var that = this
        if(that.deviceId=="" || that.deviceId== null){
          alert("设备id为空")
          return null;
        }
        let MarkToString = "{";
        if(that.map.getOverlays().length >1 || that.map.getOverlays().length==0){
          alert("请选择点位（只能选择一个点位）")
          return
        }
        for(let i in that.map.getOverlays()){
          if(i > 0)
            MarkToString = MarkToString+","
          MarkToString =MarkToString + "\""+(parseInt(i) +1)+"\":{\"lng\":\""+that.map.getOverlays()[i].getPosition().lng+"\",\"lat\":\""+that.map.getOverlays()[i].getPosition().lat+"\"}"
        }
        MarkToString = MarkToString+"}"
        if(MarkToString.length > 6){

          jQuery.ajax({
          url : this.dataForSon.baseUrl + "Zz3dmapBuildingController/saveDeviceLoLa",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            deviceId:that.deviceId,
            buildingMarks:MarkToString,

          }),
          success : function(result) {
            if (result == false) {
              alert("添加失败")
            }
            if (result == true){
              alert("添加成功")
               that.$parent.$parent.noselectLola()
            }
          },

        });

        that.map.clearOverlays();
       
        }else(
                alert("请选择点位")
        )



      },

      //打开填写信息的页面
      fun_add(message){
        var that = this
        that.dataForSon.message = message
        // 显示窗口
        that.startSubmitwindows();
        that.map.clearOverlays();    //清除地图上所有覆盖物
      },

 //打开填写设备id信息的页面
      fun_addDeviceInfo(message){
        var that = this
        that.dataForSonDeviceInfo.message = message
        // 显示窗口
        that.startSubmitwindowsDeviceInfo();
        that.map.clearOverlays();    //清除地图上所有覆盖物
      },

      init(){
        var that = this
        that.map = new BMapGL.Map('container');
        that.map.centerAndZoom(new BMapGL.Point(117.39983443466829,38.992280518223204),18);
        that.map.enableScrollWheelZoom(true);
        that.map.setDefaultCursor("crosshair");//默认十字架
        that.map.addEventListener('click', function (e) {
          if (e.overlay == null && that.deleteBuidinFlag == 0) {
            const pointLs = new BMapGL.Point(e.latlng.lng, e.latlng.lat);
            const markerLs = new BMapGL.Marker(pointLs);

            markerLs.addEventListener("click", e => {
              if(window.confirm('你确定要删除该点位？')){
                //alert("确定");
                that.deleteOverlayBymake(markerLs)

                return true;
              }else{
                //alert("取消");
                return false;
              }
            });

            that.map.addOverlay(markerLs);


          }else if(that.deleteBuidinFlag == 1){
            that.deleteBuidinFlag = 0
          }


        });




        that.ac = new BMapGL.Autocomplete(    //建立一个自动完成的对象
                {"input" : "suggestId"
                  ,"location" : that.map
                });

        that.ac.addEventListener("onhighlight", function(e) {  //鼠标放在下拉列表上的事件
          var str = "";
          var _value = e.fromitem.value;
          var value = "";
          if (e.fromitem.index > -1) {
            value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
          }
          str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

          value = "";
          if (e.toitem.index > -1) {
            _value = e.toitem.value;
            value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
          }
          str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
          that.G("searchResultPanel").innerHTML = str;
        });


        that.ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
          var _value = e.item.value;
          that.myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
          that.G("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + that.myValue;

          that.setPlace();
        });
      },

      shutdownSubmitwindows(){
        var that = this
        that.submitWindows = false

        that.dataForSon.message = ""
      },
      startSubmitwindows(){
        var that = this
        that.submitWindows = true
      },

     shutdownSubmitwindowsDeviceInfo(){
        var that = this
        that.submitWindowsDeviceInfo = false
        that.dataForSonDeviceInfo.message = ""
      },
      startSubmitwindowsDeviceInfo(){
        var that = this
        that.submitWindowsDeviceInfo = true
      }

    }
  }

</script>
<style lang='scss' scoped>
  html,
  #container {
    overflow: hidden;
    width: 100%;
    height: 99%;
    margin: 0;
    font-family: "微软雅黑";
  }

  .info {
    z-index: 999;
    width: auto;
    min-width: 22rem;
    padding: .75rem 1.25rem;
    margin-left: 1.25rem;
    margin-top: 6rem;
    position: fixed;
    top: 1rem;
    color: #000;
  }

  .sel1{
    width: 260px;
  }

  .inpt1{
    width: 260px;
    height:25px;
  }
  .submitWindowsViewClass{
    z-index: 999;
    width: auto;
    min-width: 22rem;
    padding: .75rem 1.25rem;
    margin-left: 1.25rem;
    margin-top: 10rem;
    position: fixed;
    top: 1rem;
    color: #000;
    // display: none;


  }

  .SubmitWindowsDeviceInfoViewClass{
    z-index: 999;
    width: auto;
    min-width: 22rem;
    padding: .75rem 1.25rem;
    margin-left: 1.25rem;
    margin-top: 10rem;
    position: fixed;
    top: 1rem;
    color: #000;
    // display: none;


  }
  .displayNone{
    display: none;
  }
  .displayBlock{
    display: block;
  }

</style>