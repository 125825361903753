<template>
<div id="app" style="padding: 15px">
    <el-row>
        <el-col :span="24">
            <el-page-header @back="goSelect" content="设备管理"> </el-page-header>
        </el-col>
    </el-row>
    <el-row :gutter="20">
        <el-col :span="5" :offset="0">
            <el-input placeholder="请输入设备id" v-model="deviceInfoQuery.id"></el-input>
        </el-col>
        <el-button type="primary" size="default" @click="getDeviceInfoList()">查询</el-button>
        <el-button type="success" size="default" @click="handelAdd" style="margin-right: 10px">添加设备</el-button>
        <!-- 导入导出 -->
        <el-upload style="width: 125px; display: inline-block" :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError" class="upload-demo impt" :action="baseUrl+'iotechserver/deviceInfoExcle/excel/excelImport'" multiple>
            <el-button :icon="importData.iconImport" :disabled="importData.disabledImport" type="success" class="headerButton">{{ importData.butTextImport }}</el-button>
        </el-upload>
        <el-button :icon="importData.iconExport" :disabled="importData.disabledExport" type="success" class="headerButton" @click="exportAll">{{ importData.butTextExport }}
        </el-button>
    </el-row>



    <el-row :gutter="20">
        <el-col :span="24" :offset="0">
            <el-table v-loading="loading" :data="deviceInfoList" style="width: 100%" max-height="480" :stripe="true" :border="true">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">

                            <el-form-item v-for="(item, index) in props.row.device" :label="item.proName + ':'" prop="value" style="margin-right: 50px">
                                {{ item.proName == "绑定公司"?companyNameById[item.value] :item.proName == "所属系统"? systemNameById[item.value] : item.value }}
                            </el-form-item>

                        </el-form>
                    </template>
                </el-table-column>

                <el-table-column type="index" label="编号" align="center" width="50"></el-table-column>
                <el-table-column prop="id" label="ID" align="center"></el-table-column>
                <el-table-column prop="typeName" label="设备类型" align="center"></el-table-column>

                <el-table-column fixed="right" label="操作" width="480" align="center">
                    <template slot-scope="scope">
                        <el-popconfirm v-if="scope.row.typeId != 'video'" confirm-button-text="查看" @confirm="showVideo(scope.row)" cancel-button-text="绑定" @cancel="addNewBindVideo(scope.row)" icon="el-icon-info" icon-color="green" title="查看已绑定视频还是绑定新的视频设备？">
                            <el-button slot="reference" :type="scope.row.videoUrl != null ? 'success' : 'warning'" size="small" style="margin-right: 11px">视频</el-button>
                        </el-popconfirm>
                        <el-button v-else slot="reference" @click="showVideo(scope.row)" :type="scope.row.videoUrl != null ? 'success' : 'warning'" size="small">视频</el-button>
                        <el-button @click="handleEdit(scope.row)" type="primary" size="small">编辑</el-button>
                        <el-button @click="toHistory" type="primary" size="small">历史记录</el-button>
                        <el-button @click="toCRT(scope.row)" type="primary" size="small">CRT</el-button>
                        <el-button @click="handleRemove(scope.row.id)" type="danger" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>

    <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center" @current-change="getDeviceInfoList"></el-pagination>

          
    <el-dialog  title="设备信息"  :visible.sync="dialogVisible" width="30%" @close="clearDeviceInfo" :close-on-click-modal="false">
        
        <SelectLoLaEditDeviceViews v-bind:deviceId="deviceInfo.id"  ref="SelectLoLaEditDeviceViews" :class="{submitWindowsViewClass:true, displayBlock:this.submitWindows,displayNone:!this.submitWindows,}"></SelectLoLaEditDeviceViews>

        <el-form :model="deviceInfo" ref="form" label-width="120px" :inline="false" size="normal" :rules="rules">
            <el-form-item label="ID" prop="id" :disabled="addOrEditFlag == 'edit'">
                <el-input v-model="deviceInfo.id" :disabled="addOrEditFlag == 'edit'"></el-input>
            </el-form-item>

            <el-form-item label="选取坐标点">
                <el-button @click="selectLola">点击选择</el-button>
            </el-form-item>

            <el-form-item label="设备类型" prop="typeId">
                <el-select v-model="typeName" clearable placeholder="请选择设备类型" @change="buiding" style="width:100%">
                    <el-option v-for="item in deviceType" :key="item.id" :label="item.name" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item v-for="(item, index) in selectDeviceTypeInfo.properties" :label="item" prop="value" v-if='item !="绑定公司" && item !="所属系统" '>
                <el-input v-if='item !="微信通知"&& item !="短信通知"&& item !="语音通知"&& item !="通知电话"' v-model="deviceInfo.value[index]" placeholder="静态属性的值"></el-input>
            

            <el-input v-if='item =="微信通知"' v-model="deviceInfo.value[index]" placeholder="静态属性的值"></el-input>
            <el-input v-if='item =="短信通知"' v-model="deviceInfo.value[index]" placeholder="静态属性的值"></el-input>
            <el-input v-if='item =="语音通知"' v-model="deviceInfo.value[index]" placeholder="静态属性的值"></el-input>
            <el-input v-if='item =="通知电话"' v-model="deviceInfo.value[index]" placeholder="静态属性的值"></el-input>


            </el-form-item>




            <el-form-item label="绑定公司" prop="typeId" v-if="!sxsbshlw">
                <el-cascader placeholder="请选择需要绑定的公司（默认 无）" :show-all-levels="false" clearable ref="cascader" v-model="checkedCom" :options="allCompanyList" @change="checkedCompany" :props="defaultProps" style="width:100%">
                </el-cascader>
            </el-form-item>

            <el-form-item label="所属系统" v-if="!sxsbshlw">
                <el-select v-model="checkedSys" clearable placeholder="请选择所属系统（默认 无）" style="width:100%" @change="checkedSystem">
                    <el-option v-for="item in permissionsSystemName" :key="item.id" :label="item.bigSystemName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
             <el-button @click="dialogVisibleFun">取 消</el-button>  
            <el-button type="primary" @click="addOrEditDeviceInfo" :disabled="!typeName">确 定</el-button>
        </div>
    </el-dialog>




    <!-- 绑定视频弹窗 -->
    <el-dialog title="绑定视频" :visible.sync="videoDialogVisible" width="25%" @close="clearDeviceStaticProperties">
        <el-form :model="deviceStaticProperties" ref="formVideo" label-width="120px" :inline="false" size="normal">
            <el-form-item label="绑定视频1">
                <el-select v-model="deviceStaticProperties.videoInfoId1" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-1'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频2" v-show="deviceStaticProperties.videoInfoId1 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId2" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-2'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频3" v-show="deviceStaticProperties.videoInfoId2 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId3" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-3'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频4" v-show="deviceStaticProperties.videoInfoId3 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId4" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-4'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频5" v-show="deviceStaticProperties.videoInfoId4 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId5" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-5'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频6" v-show="deviceStaticProperties.videoInfoId5 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId6" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-6'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频7" v-show="deviceStaticProperties.videoInfoId6 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId7" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-7'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频8" v-show="deviceStaticProperties.videoInfoId7 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId8" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-8'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="绑定视频9" v-show="deviceStaticProperties.videoInfoId8 != ''">
                <el-select v-model="deviceStaticProperties.videoInfoId9" style="margin-left: 10px" clearable placeholder="请选择视频" @change="refreshVideoList">
                    <el-option v-for="item in videoList" :key="item.id + '-9'" :label="item.id" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
             <el-button @click="videoDialogVisible = false">取 消</el-button>  
            <el-button type="primary" @click="realBindVideo">绑定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
// import jurisBigSmallSys from "@/api/managementApi/JurisBigSmallSys";

  import SelectLoLaEditDeviceViews from "@/views/mapBuiding/SelectLoLaEditDevice.vue";

import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";
import companySystem from "@/api/managementApi/CompanyInfo";
import dataStorage from '@/../utils/dataStorage.js'
import deviceInfo from "../../api/manageApi/DeviceInfoManagement";
import DeviceType from "../../api/manageApi/DeviceTypeManagement";
import crtDeviceCoordinate from "../../api/manageApi/CrtDeviceCoordinate";
import baseDate from "../../assets/config/BaseData"
// import header from "../../components/header.vue";
export default {
    // components: { myheader: header },
    components: {
      SelectLoLaEditDeviceViews
    },
    data() {
        return {
            sxsbshlw:false,//所选设备类型是否是个人设备
            submitWindows:false,
            zbd:{
                lat:null,
                lng:null
            },//提交设备的坐标点 
            dqafyzb:"未选择",//当前设备是否已经选择了坐标点
            allCompanyList: [], // 绑定公司
            permissionsSystemName: [], // 所属系统
            defaultProps: {
                children: "smallCompanyList", // 子公司节点
                label: "name", // 公司名
                value: "id",
                checkStrictly: true,
            },
            companyNameById: {},
            systemNameById: {},
            checkedCom: '', // 绑定公司选择
            checkedSys: '', // 所属系统选择
            checkedValue1: [], // 选择的公司
            checkedValue2: [], // 选择的权限
            //分页相关数据
            current: 1, //当前页
            limit: 5, //每页显示条数
            total: 0, //数据总数
            videoList: [],
            deviceStaticProperties: {
                deviceInfoId: "",
                videoInfoId1: "",
                videoInfoId2: "",
                videoInfoId3: "",
                videoInfoId4: "",
                videoInfoId5: "",
                videoInfoId6: "",
                videoInfoId7: "",
                videoInfoId8: "",
                videoInfoId9: "",
            },
            typeName: "",
            selectDeviceTypeInfo: {},
            deviceType: [],
            typeName: "", //设备类型
            typeId: "", //设备类型id
            //页面数据
            deviceInfoList: [], //设备类型集合
            deviceInfo: {
                //当前编辑或添加的设备信息
                id: "",
                typeId: "",
                typeName: "",
                name: [],
                value: [],
            },
            rules: {
                id: [{
                    required: true,
                    message: "请输入设备ID",
                    trigger: "blur",
                }, ],
                typeId: [{
                    required: true,
                    message: "请输入设备类型ID",
                    trigger: "blur",
                }, ],
                name: [{
                    required: true,
                    message: "请输入设备静态属性",
                    trigger: "blur",
                }, ],
                value: [{
                    required: true,
                    message: "请输入设备类型ID",
                    trigger: "blur",
                }, ],
            },
            deviceInfoQuery: {
                //模糊查询条件对象
                id: "",
            },
            deviceStaticNameTemp: "", //追加静态属性
            valNameTemp: "", //追加动态属性

            //标志位
            loading: true,
            dialogVisible: false, //添加、编辑弹框的标志位
            addOrEditFlag: "add", //添加或者编辑的标志位  add是添加   edit是编辑
            videoDialogVisible: false, //绑定视频标志位
            importData: {
                //导入数据 工具对象
                butTextImport: "批量导入",
                butTextExport: "批量导出",
                iconImport: "el-icon-download",
                iconExport: "el-icon-upload2",
                disabledImport: false,
                disabledExport: false,
            },
            baseUrl: baseDate.baseUrl
        };
    },
    created() {
        this.jurisSysName()
        this.getAllCompanyList()
        this.getCompanyInfo()
        this.init();

     if(dataStorage.getBaseData("deviceInfomanagemtrelode")==0 || dataStorage.getBaseData("deviceInfomanagemtrelode") == null){
      window.location.reload()
      dataStorage.setBaseData("deviceInfomanagemtrelode", 1)
    }else{
      dataStorage.setBaseData("deviceInfomanagemtrelode", 0)
    }

    },
    methods: {
        initSelectLoLa(){
            this.$nextTick(()=>{console.log("11111111111111")})

        },
        getCompanyInfo() {
            companySystem.getCompanyInfo().then((res) => {
                for (let i in res.data.data.list) {
                    this.companyNameById[res.data.data.list[i]["id"]] = res.data.data.list[i]["name"]
                }
            });
        },

        // 查询公司结构
        getAllCompanyList() {
            companySystem.getAllCompanyStructure().then((res) => {
                // 公司结构集合
                this.allCompanyList = res.data.data.list;
                console.log(res.data.data.list);
                // this.companyNameById
                let lsCompany = {}

            });
        },

        // 获取权限系统名称
        jurisSysName() {
            noticeSysManagement.getNotifyBigSystemById().then((res) => {
                this.systemNameById = {}
                this.permissionsSystemName = res.data.data.list;
                for (let i in this.permissionsSystemName)
                    this.systemNameById[this.permissionsSystemName[i].id] = this.permissionsSystemName[i].bigSystemName
            });
        },

        // 选择的公司
        checkedCompany() {

            this.checkedValue1 = this.$refs.cascader.getCheckedNodes()[0].data.id;
            // 选择后让选择框消失
            this.$refs.cascader.dropDownVisible = false;

            // this.deviceInfo.name.push("绑定公司")

            for (let i = 0; i < this.deviceInfo.name.length; i++) {

                if (this.deviceInfo.name[i] == "绑定公司") {
                    this.deviceInfo.value[i] = this.checkedValue1
                    break;
                }
                if (i == this.deviceInfo.name.length - 1) {
                    this.deviceInfo.name.push("绑定公司")
                    this.deviceInfo.value.push(this.checkedValue1)
                }
            }

        },

        //  选择的系统
        checkedSystem(val) {

            for (let i = 0; i < this.deviceInfo.name.length; i++) {
                if (this.deviceInfo.name[i] == "所属系统") {
                    this.deviceInfo.value[i] = this.checkedSys
                    break;
                }
                if (i == this.deviceInfo.name.length - 1) {
                    this.deviceInfo.name.push("所属系统")
                    this.deviceInfo.value.push(this.checkedSys)
                }
            }
        },

        // *----------------------------------------*

        goSelect() {
            this.$router.push("/Select");
        },
        init() {
            this.getDeviceInfoList();
        },
        toCRT(row) {

            crtDeviceCoordinate.getCoordinateByDeviceId(row.id).then((res) => {
                console.log(res);
                if (res.data.message != "失败" ) {
                    console.log(res.data);
                    this.$router.push({
                        name: "CRTShow",
                        params: {
                            CRTId: res.data.data.data.crtPictureId,
                            deviceId: res.data.data.data.deviceInfoId,
                        },
                    });
                } else {
                
                    this.$message({
                        type: "warning",
                        message: "该设备尚未添加点位！",
                    });
                }
            });
        },

        toHistory() {
            this.$router.push({
                path: "/History",
            });
        },

        refreshVideoList(val) {
            for (let index = 0; index < this.videoList.length; index++) {
                if (this.videoList[index].id == val) {
                    this.videoList.splice(index, 1);
                    continue;
                }
            }
        },
        getAllVideo() {
            deviceInfo
                .getAllVideo(this.deviceStaticProperties.deviceInfoId)
                .then((res) => {
                    this.videoList = res.data.data.list;
                });
        },
        clearDeviceStaticProperties() {
            this.deviceStaticProperties = {
                deviceInfoId: "",
                videoInfoId1: "",
                videoInfoId2: "",
                videoInfoId3: "",
                videoInfoId4: "",
                videoInfoId5: "",
                videoInfoId6: "",
                videoInfoId7: "",
                videoInfoId8: "",
                videoInfoId9: "",
            };
        },
        realBindVideo() {
            // if (this.deviceStaticProperties.videoInfoId1 == "") {
            //     this.$message({
            //         type: "error",
            //         message: "请至少选择一个视频设备后再进行绑定！",
            //     });
            // }
            //  else {
            deviceInfo.bindVideo(this.deviceStaticProperties).then((res) => {
                this.$message({
                    type: res.data.success ? "success" : "error",
                    message: res.data.message,
                });
                if (res.data.success) {
                    this.videoDialogVisible = false;
                    this.getDeviceInfoList();
                }
            });
            // }
        },
        //视频
        showVideo(row) {
            if (row.videoUrl) {
                console.log(row.videoUrl);
                window.location.href = row.videoUrl;
            } else {
                this.$message({
                    type: "error",
                    message: "该设备未绑定任何摄像头！",
                });
            }
        },
        addNewBindVideo(row) {
            this.deviceStaticProperties = {
                deviceInfoId: row.id,
                videoInfoId1: row.videoInfoId1,
                videoInfoId2: row.videoInfoId2,
                videoInfoId3: row.videoInfoId3,
                videoInfoId4: row.videoInfoId4,
                videoInfoId5: row.videoInfoId5,
                videoInfoId6: row.videoInfoId6,
                videoInfoId7: row.videoInfoId7,
                videoInfoId8: row.videoInfoId8,
                videoInfoId9: row.videoInfoId9,
            };
            this.deviceStaticProperties.deviceInfoId = row.id;
            this.videoDialogVisible = true;
            this.getAllVideo();
        },
        //多条件分页查询设备类型
        getDeviceInfoList() {
            var that = this;
            that.deviceInfoQuery.id;
            deviceInfo
                .getDeviceInfoList(this.current, this.limit, this.deviceInfoQuery)
                .then((res) => {
                    this.total = res.data.data.total;
                    this.deviceInfoList = res.data.data.rows;
                    if (res.data.success) {
                        this.loading = false;
                    }
                });
        },
        //添加或者编辑设备类型信息
        addOrEditDeviceInfo() {

            let flagThis = false;
            if (
                this.deviceInfo.id != "" &&
                this.deviceInfo.id != null &&
                this.deviceInfo.typeId != "" &&
                this.deviceInfo.typeId != null &&
                this.deviceInfo.name.length == this.deviceInfo.value.length
            ) {
                flagThis = true;

                this.deviceInfo.name.forEach((element) => {
                    if (element == "") flagThis = false;
                });
                this.deviceInfo.value.forEach((element) => {
                    if (element == "") flagThis = false;
                });
            }
            if (!flagThis) {
                alert("请填写全部信息");
                return null;
            }
            if (this.addOrEditFlag == "add") {
                deviceInfo.addDeviceInfo(this.deviceInfo).then((res) => {
                    if (res.data.success) {
                        //提示信息
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        //回到页面并刷新
                        this.dialogVisible = false; //关闭弹窗
                        this.clearDeviceInfo(); //清空设备类型信息对象数据
                        this.getDeviceInfoList(); //刷新页面数据
                    } else {
                        //提示信息
                        this.$message({
                            type: "warning",
                            message: res.data.message,
                        });
                    }
                });
            } else {
                deviceInfo.updataDeviceInfo(this.deviceInfo).then((res) => {
                    if (res.data.success) {
                        //提示信息
                        this.$message({
                            type: "success",
                            message: res.data.message,
                        });
                        //回到页面并刷新
                        this.dialogVisible = false; //关闭弹窗

                        this.clearDeviceInfo(); //清空设备类型信息对象数据
                        this.getDeviceInfoList(); //刷新页面数据
                    } else {
                        //提示信息
                        this.$message({
                            type: "warning",
                            message: res.data.message,
                        });
                    }
                });
            }
        },

        //根据设别类型id删除设备类型信息
        handleRemove(id) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                //点击确定，指定then方法
                deviceInfo.removeDeviceInfo(id).then((res) => {
                    //提示信息
                    this.$message({
                        type: res.data.success ? "success" : "warning",
                        message: res.data.message,
                    });
                    //回到页面并刷新
                    this.getDeviceInfoList();
                });
            });
        },

        //添加设备类型信息弹框
        handelAdd() {

            var that = this;

            that.zbd={
                lat:null,
                lng:null
            },//提交设备的坐标点 
           that.dqafyzb="未选择"

            that.checkedSys = ""

            that.typeName = "";
            that.selectDeviceTypeInfo = {};
            that.deviceInfo.name = [];
            that.deviceInfo.value = [];
            this.checkedCom = ""
            this.checkedSys = ""

            this.dialogVisible = true;
            this.addOrEditFlag = "add";

            //获取所有设备类型
            DeviceType.getDeviceTypeList(1, 999, {
                name: "",
            }).then((resp) => {
                if (resp) {
                    this.deviceType = resp.data.data.rows;
                }
            });
        },
        //修改设备类型信息弹框数据加载
        handleEdit(row) {
            this.clearDeviceInfo()

            var that = this;

            //获取所有设备类型
            DeviceType.getDeviceTypeList(1, 999, {
                name: "",
            }).then((resp) => {
                if (resp) {
                    this.deviceType = resp.data.data.rows;
                }
            });

            that.typeName = row.typeName;

            if(row.name != null)
            for (let i = 0; i < row.name.length; i++) {
                if (row.name[i] == "所属系统" || row.name[i] == "绑定公司") {
                    if (row.name[i] == "所属系统") {
                        that.checkedSys = this.systemNameById[row.value[i]]
                    } else {
                        that.checkedCom = row.value[i]
                    }
                }
            }

            that.selectDeviceTypeInfo = {
                properties: row.name,
                id: row.typeId,
                name: row.typeName,
            };
            this.deviceInfo = {
                id: row.id,
                typeId: row.typeId,
                name: row.name,
                value: row.value,
            };
            this.dialogVisible = true;
            this.addOrEditFlag = "edit";

            that.sxsbshlw = false
            if(that.deviceInfo.typeId.length>2 && "GR"==that.deviceInfo.typeId.substring(that.deviceInfo.typeId.length-2)){
                that.sxsbshlw = true;

            }
        },
        removeLastOneFromProperties() {
            this.deviceInfo.name.pop();
            this.deviceStaticNameTemp = "";
        },
        //追加静态属性的值
        // addToVal() {
        //     this.deviceInfo.value.push(this.valNameTemp);

        //     this.valNameTemp = "";
        // },
        removeLastOneFromVal() {
            this.deviceInfo.value.pop();
            this.valNameTemp = "";
        },

        //清空设备类型信息对象
        clearDeviceInfo() {
            this.deviceInfo = {
                id: "",
                typeId: "",
                name: [],
                value: [],
                typeName: "",
            };

            this.checkedSys = ""
            this.checkedCom = ""
            this.getDeviceInfoList();
        },

        //返回上一级
        goBack() {
            window.history.back();
        },
        buiding(selectInfo) {
            var that = this;
            that.deviceInfo.typeId = "";
            that.selectDeviceTypeInfo = selectInfo;
            that.typeName = that.selectDeviceTypeInfo.name;
            that.deviceInfo.typeId = that.selectDeviceTypeInfo.id;

            that.sxsbshlw = false
            if(that.deviceInfo.typeId.length>2 && "GR"==that.deviceInfo.typeId.substring(that.deviceInfo.typeId.length-2)){
                that.sxsbshlw = true;

            }
            

            that.deviceInfo.name = [];
            that.deviceInfo.value = [];
            that.selectDeviceTypeInfo.properties.forEach((element) => {
                that.deviceInfo.name.push(element);
                this.deviceInfo.value.push("")

            });
            for (let i = 0; i < this.deviceInfo.name.length; i++) {
                if (this.deviceInfo.name[i] == "绑定公司") {
                    break;
                }
                if (i == this.deviceInfo.name.length - 1) {
                    this.deviceInfo.name.push("绑定公司")
                    this.deviceInfo.value.push("无")
                }
            }

            for (let i = 0; i < this.deviceInfo.name.length; i++) {
                if (this.deviceInfo.name[i] == "所属系统") {
                    break;
                }
                if (i == this.deviceInfo.name.length - 1) {
                    this.deviceInfo.name.push("所属系统")
                    this.deviceInfo.value.push("无")
                }

            }
        },
        dialogVisibleFun() {
            var that = this;
            that.typeName = "";
            that.selectDeviceTypeInfo = {};
            that.dialogVisible = false;
            that.deviceInfo.name = [];
            that.deviceInfo.value = [];
        },
        beforeUpload() {
            //上传文件之前的钩子
            this.importData.iconImport = "el-icon-loading";
            this.importData.butTextImport = "正在导入";
            this.importData.disabledImport = true;
        },
        onSuccess(response) {
            //上传文件成功的钩子
            this.importData.disabledImport = false;
            this.importData.iconImport = "el-icon-download";
            this.importData.butTextImport = "批量导入";
            if(response.code == 20000){
                this.$message.success(response.message);
            }
            if(response.code == 20001){
                this.$message.error(response.message);
            }
            this.init();
        },
        onError() {
            //上传文件失败的钩子
            this.importData.disabledImport = false;
            this.importData.iconImport = "el-icon-download";
            this.importData.butTextImport = "批量导入";
            this.$message.error("导入失败！");
        },
        exportAll() {
            //批量导出  这里能成功请求后端接口并返回数据 但是后端响应的是二进制数据，这里只能接收JSON等数据。

            this.importData.butTextExport = "正在下载";
            this.importData.iconExport = "el-icon-loading";
            this.importData.disabledExport = true;

            //  window.open("/nechserver/valAnalyticRule/excelExport"); 下面的方式更协调

            //创建一个FORM表单，提交表单的方式下载
            var studentName = 1;
            var form = document.createElement("form");
            form.id = "form";
            form.name = "form";
            //把这个form放在body里面

            document.body.appendChild(form);
            //给form里面创建一个input框，隐藏掉，来存参数
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "studentName";
            input.value = studentName;
            var input1 = document.createElement("input");
            input1.type = "hidden";
            input1.name = "token";
            input1.value = window.localStorage.getItem("iotechtj_token");
            //把input放在form里面
            form.appendChild(input);
            form.appendChild(input1);
            form.method = "RequestMethod.get"; //请求方式
            form.action =
                baseDate.baseUrl + 'iotechserver/deviceInfoExcle/excel/excelExport';
            form.submit();
            //删掉form
            document.body.removeChild(form);

            this.interval = setInterval(() => {
                this.getCookie();
            }, 200);
        },
        getCookie() {
            //获取cookie
            console.log("获取到ISOVER", this.$Cookies.get("ISOVER"));

            if (
                this.$Cookies.get("ISOVER") != undefined &&
                this.$Cookies.get("ISOVER") == "1"
            ) {
                this.importData.butTextExport = "批量导出";
                this.importData.iconExport = "el-icon-upload2";
                this.importData.disabledExport = false;
                this.$Cookies.set("ISOVER", "0");
                clearInterval(this.interval);
            }
        },
        selectLola(){
            if( this.deviceInfo.id == "" ||
                this.deviceInfo.id == null){
                alert("请填写设备号")
            }else{
                // 打开设备提交
                this.submitWindows = true


            }
        },
        noselectLola(){
            this.submitWindows = false

        }
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload__input {
    display: none !important;
}

// .el-button + .el-button {
//   margin-right: 10px;
// }
.middle {
    display: flex;
    justify-content: space-around;
    width: 18%;
}

.el-table {
    border-radius: 8px;
    background-color: #bdc0c8;
}

.el-upload__input {
    display: none;
}

/deep/ .el-table thead {
    color: #000;
    font-weight: 700;
}

.el-row {
    margin-bottom: 20px;
}

/deep/ .in-b {
    display: inline;
}

/deep/ .el-table td,
.el-table th {
    padding: 8px 0;
}
  .submitWindowsViewClass{
    z-index: 999;
    width: 600px;
    height: 500px;
    margin-left:-50px;
    margin-top: 50px;
    position: fixed;
    top: 1rem;
    color: #000;
    // display: none;


  }
</style>
